import React from 'react';
import FormBuilder, {FormBuilderProps} from "../form/FormBuilder.tsx";
import {FormikValues} from "formik/dist/types";
import HModal from "./HModal.tsx";

export interface ModalFormBuilderProps<T extends FormikValues, S> extends FormBuilderProps<T, S> {
  show: boolean;
  handleClose: () => void;
  title: string;
  subtitle?: string;
  width?: number;
  submitText?: string;
}

function ModalFormBuilder<T extends FormikValues, S>({show = false, handleClose, fieldsets, title, subtitle, formSchema, action, onSubmit, initialValues, width, submitText}: ModalFormBuilderProps<T, S>) {
  return (
    <HModal
      show={show}
      handleClose={handleClose}
      backdrop={true}
      title={title}
      subtitle={subtitle}
      width={width}
      padding={6}
    >
      <FormBuilder<T, S>
        fieldsets={fieldsets}
        formSchema={formSchema}
        className={''}
        action={action}
        onSubmit={onSubmit}
        initialValues={initialValues}
        submitText={submitText}
      />
    </HModal>
  );
}

export default ModalFormBuilder;
